import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storeSynchronize from 'redux-localstore';
import NewsReducer from './news/reducer';
import TermReducer from './terms/reducer';
import SearchReducer from './search/reducer';

const reducer = combineReducers({
    news: NewsReducer,
    terms: TermReducer,
    search: SearchReducer
})
export const store = configureStore({
    reducer, middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});

storeSynchronize(store, {
    blacklist: ['search']
});