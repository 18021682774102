import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore'
import { Term } from '../../models';
import { TermState } from "./types";

const defaultState: TermState = {
    items: []
}
const initialState = defineState(defaultState)('terms');

export const slice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        setTerms: (state: TermState, action: PayloadAction<Term[]>) => {
            const items = [...state.items];
            for (let i = 0; i < action.payload.length; i++) {
                const term = action.payload[i];
                if (items.findIndex((item) => item.id === term.id) !== -1) {
                    continue;
                }
                items.push(term);
            }
            state.items = items;
        },
        addTerm: (state: TermState, action: PayloadAction<Term>) => {
            const items = [...state.items];
            if (items.findIndex((item) => item.id === action.payload.id) !== -1) {
                return;
            }
            items.push(action.payload);
            state.items = items;
        },
    }
});

export default slice.reducer;