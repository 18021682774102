import React from 'react';
import Loadable from 'react-loadable';

export interface IRoute {
    path: string;
    exact: boolean;
    component: any;
}
const Loading = () => (
    <div className="loading">
        <div>
            <div></div>
            <div></div>
        </div>
    </div>
);

const AsyncHome = Loadable({
    loader: () => import('./pages/HomePage'),
    loading: Loading,
    modules: ['home'],
});

const AsyncAbout = Loadable({
    loader: () => import('./pages/AboutPage'),
    loading: Loading,
    modules: ['about'],
});

const AsyncPartner = Loadable({
    loader: () => import('./pages/PartnerPage'),
    loading: Loading,
    modules: ['partner'],
});

const AsyncCredit = Loadable({
    loader: () => import('./pages/CreditPage'),
    loading: Loading,
    modules: ['credit'],
});

const AsyncContact = Loadable({
    loader: () => import('./pages/ContactPage'),
    loading: Loading,
    modules: ['contact'],
});

const routes: IRoute[] = [
    { path: '/', exact: true, component: AsyncHome },
    { path: '/sobre', exact: true, component: AsyncAbout },
    { path: '/apoios', exact: true, component: AsyncPartner },
    { path: '/creditos', exact: true, component: AsyncCredit },
    { path: '/contactos', exact: true, component: AsyncContact }
];

export default routes;