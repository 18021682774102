import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore'
import { News } from '../../models';
import { NewsState } from "./types";

const defaultState: NewsState = {
    items: []
}
const initialState = defineState(defaultState)('news');

export const slice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsResult: (state: NewsState, action: PayloadAction<News[]>) => {
            state.items = action.payload;
        },
    }
});

export default slice.reducer;