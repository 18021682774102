import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Term } from '../../models';
import { SearchState } from "./types";

const initialState: SearchState = {
    opened: false,
    tabIndex: 0
}

export const slice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setOpened: (state: SearchState, action: PayloadAction<boolean>) => {
            state.opened = action.payload;
        },
        setTerm: (state: SearchState, action: PayloadAction<string>) => {
            state.term = action.payload;
        },
        setTerms: (state: SearchState, action: PayloadAction<Term[]>) => {
            state.founded = action.payload;
        },
        setSelectedIndex: (state: SearchState, action: PayloadAction<number>) => {
            state.selectedIndex = action.payload;
        },
        setTabIndex: (state: SearchState, action: PayloadAction<number>) => {
            state.tabIndex = action.payload;
        },
        setHandConfigurations: (state: SearchState, action: PayloadAction<number[]>) => {
            state.handConfigurations = action.payload;
        },
        setSignLocations: (state: SearchState, action: PayloadAction<number[]>) => {
            state.signLocations = action.payload;
        },
        setThemes: (state: SearchState, action: PayloadAction<number[]>) => {
            state.themes = action.payload;
        },
        clear: (state: SearchState) => {
            state.term = undefined;
            state.founded = undefined;
            state.selectedIndex = undefined;
            state.tabIndex = 0;
            state.handConfigurations = undefined;
            state.signLocations = undefined;
            state.themes = undefined;
        },
    }
});

export default slice.reducer;